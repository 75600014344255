/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, TextField } from '@mui/material';
import ReactPlayer from 'react-player';
import { __DirectiveLocation } from 'graphql';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faRotateLeft, faPause } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/loading/Loading'

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const VideoContainer = styled.div`
  display: flex;
  position: relative;
`

const ControlsContainer = styled.div`
    width: 100%;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledIcon = styled(FontAwesomeIcon)`
    height: 24px;
    cursor: pointer;
    margin-right: 8px;

    path {
        fill: #2065D1;
    }

    &:hover {
        path{
            fill: #103996;
        }
    }
`

const StyledTextField = styled(TextField)`
    div {
        padding: 0;
    }
    input {
        padding: 8px 16px;
    }
`

SessionPageVideoPlayer.propTypes = {
    session: PropTypes.object,
    startTime: PropTypes.object,
    isPlaying : PropTypes.bool,
    videoFileLocation: PropTypes.string,
    videoRef: PropTypes.object,
    onVideoReady: PropTypes.func,
    playClicked: PropTypes.func,
    pauseClicked: PropTypes.func,
    resetClicked: PropTypes.func,
    lagTime: PropTypes.number,
    lagTimeChanged: PropTypes.func,
};

function SessionPageVideoPlayer({
    session,
    isPlaying ,
    videoFileLocation,
    videoRef,
    onVideoReady,
    playClicked,
    pauseClicked,
    resetClicked,
    lagTime,
    lagTimeChanged,
}) {
    const [videoUrl, setVideoUrl] = useState('');
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [isVideoReady, setIsVideoReady] = useState(false);

    const [error, setError] = useState(false);

    const handleOnError = () => {
        setError(true);
    };

    useEffect(() => {
        setVideoUrl(videoFileLocation);
        setVideoLoaded(true);
    }, [session, videoFileLocation]);

    const handleOnReady = () => {
        
        setIsVideoReady(true);
        onVideoReady();
    }

    const updateLagTime = (event) => {
        const numberVal = parseInt(event.target.value, 10);
        lagTimeChanged(numberVal);
    }

    return (
        <Card>
            <VideoContainer>
                {!isVideoReady && <LoadingContainer>
                    <Loading/>
                </LoadingContainer>}
                <ReactPlayer 
                    ref={videoRef} 
                    url={videoUrl} 
                    playing={isPlaying} 
                    onError={handleOnError} 
                    onReady={handleOnReady}
                />
            </VideoContainer>
            <ControlsContainer>
                <div>
                    <StyledIcon 
                        icon={isPlaying ? faPause: faPlay} 
                        onClick={isPlaying ? pauseClicked : playClicked}
                    />
                    <StyledIcon icon={faRotateLeft} onClick={resetClicked}/>
                </div>
                <StyledTextField
                    id="lagTimeNumber"
                    label="Lag Time (Milliseconds)"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={lagTime}
                    onChange={updateLagTime}
                />
            </ControlsContainer>
        </Card>
    );
}

export default SessionPageVideoPlayer;