/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Card,
    CardContent,
    Divider,
    Button,
    CardHeader,
    CardActionArea,
} from '@mui/material';

import {
    Timeline,
    TimelineConnector,
} from '@mui/lab'
import StandardSelector from '../../../components/standard-selector';

SessionDetailsCard.propTypes = {
    session: PropTypes.object,
    currentTime: PropTypes.object,
    standards: PropTypes.array,
    addSelectedStandard: PropTypes.func,
    activeStandards: PropTypes.object,
    removeActiveStandard: PropTypes.func,
    createStandard: PropTypes.func,
    closeCreateStandardDialog: PropTypes.func,
}

function SessionDetailsCard(props) {

    let formattedCurrentTime;
    if (props.currentTime === undefined) {
        formattedCurrentTime = new Date().toLocaleString();
    } else {
        formattedCurrentTime = props.currentTime.toLocaleString();

    }

    const [activeStandardsState, setActiveStandardsState] = useState(props.activeStandards);

    // update state when props change
    React.useEffect(() => {
        setActiveStandardsState(props.activeStandards);
    }, [props.activeStandards]);
  
    return (
        <Card>
            <CardContent>
                <Typography variant="h4">
                    Recording Details
                </Typography>
                <Divider />
                <br />
                <Typography variant="p" sx={{ mb: 5 }}>
                    {props.session.description.length === 0 ? "No Description" : props.session.description}
                </Typography>
                <br/>
                <Divider />
                <br />
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Current Time : {formattedCurrentTime}
                </Typography>
                <Divider /> 
                <br />
                <StandardSelector
                    standards={props.standards}
                    onSelect={props.addSelectedStandard} 
                    activeStandards={activeStandardsState} 
                    addActiveStandard={props.addSelectedStandard}
                    removeActiveStandard={props.removeActiveStandard}
                    createStandard={props.createStandard}
                    closeCreateStandardDialog={props.closeCreateStandardDialog}/>
            </CardContent>
        </Card>
    );
}

export default SessionDetailsCard;