import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { ToastContainer} from 'react-toastify';
import { errorNotification, infoNotification } from '../../../services/notificationService';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../../../components/iconify';

import { passwordReset } from '../../../services/authService';

// ----------------------------------------------------------------------

PasswordResetForm.propTypes = {
  token: PropTypes.string
}


export default function PasswordResetForm(props) {
  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    if (!isValidInput()){
      errorNotification('Please Check your details and try again');
    } else {
      passwordReset(props.token, emailAddress, password).then(() => {
        infoNotification('Password reset successful. Redirecting to login page');
        setTimeout(() => navigate('/login', { replace: true }), 1000);
      }).catch((e) => {
        errorNotification(e.response.data.message);
        setAlreadySubmitted(false);
      }
    );
    }

  };

  const isValidInput = () => {
    if (emailAddress === '' || password === '' || confirmPassword === '') {
      errorNotification('Please fill in all fields');
      return false;
    }
    if (password !== confirmPassword) {
      errorNotification('Passwords do not match');
      return false;
    }
    return true;
  }
  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e) => setEmailAddress(e.target.value)} disabled={alreadySubmitted}/>
        <TextField
          name="password"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          name="password"
          label="Confirm New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Stack>


      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}/>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} disabled={alreadySubmitted}>
        Reset Password
      </LoadingButton>
      <ToastContainer />
    </>
  );
}
