import React from 'react';
import PropTypes from 'prop-types';

SessionSearchBar.propTypes = {
    placeHolderText: PropTypes.string,
};


function SessionSearchBar(props) {
    return (
        <div>
            <h1>{props.placeHolderText}</h1>
        </div>
    );
}

export default SessionSearchBar;