import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Divider, Button } from '@mui/material';
import { StandardChipDisplay } from '../standard-chip-display';

import { RuleSelectionScreen } from '../rule-selection-screen';

StandardSelector.propTypes = {
    standards: PropTypes.array,
    activeStandards: PropTypes.object,
    removeActiveStandard: PropTypes.func,
    createStandard: PropTypes.func,
    addActiveStandard: PropTypes.func,
};

function StandardSelector(props) {

    const [seeLoadingSymbol, setSeeLoadingSymbol] = useState(false);
    
    const [showRuleSelectionScreen, setShowRuleSelectionScreen] = useState(false);

    
    const onPress = () => {
        setShowRuleSelectionScreen(true);
    }

    const onCloseOfSelectRuleScreen = () => {
        setShowRuleSelectionScreen(false);
    }

    const removeActiveStandard = (standard) => {
        props.removeActiveStandard(standard.id);
        setSeeLoadingSymbol(true);
    }

    useEffect(() => {
        window.setTimeout(() => {
            setSeeLoadingSymbol(false);
        } , 1000);

    }, [seeLoadingSymbol]);

    return (
            <>
            {showRuleSelectionScreen ? 
            <RuleSelectionScreen
                removeActiveStandard={removeActiveStandard}
                addActiveStandard={props.addActiveStandard}
                standards={props.standards}
                activeStandards={props.activeStandards}
                open={showRuleSelectionScreen}
                handleClose={onCloseOfSelectRuleScreen}
                createStandard={props.createStandard}/> 
            : null} 
                <Button
                onClick={onPress}
                >Select Rules to Apply</Button>
                <Divider />
            <StandardChipDisplay standards={props.activeStandards} onDelete={removeActiveStandard} color={'success'}/>
            </>
        );
}

export default StandardSelector;