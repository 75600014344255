/* eslint-disable react-hooks/exhaustive-deps */

import { Card, Tab, Tabs, Typography } from '@mui/material';
import {React, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import SessionChartTab from './SessionChartTab';
import { friendlyNameConverter } from '../../../services/localizationService';

SessionPageChartTabbedDisplay.propTypes = {
    session: PropTypes.object,
    events: PropTypes.array,
    currentTime: PropTypes.object,
    sensorByTypes: PropTypes.object,
    onChartClick: PropTypes.func,
};


export default function SessionPageChartTabbedDisplay({session, events, currentTime, sensorByTypes, onChartClick}) {

    const [tabIndex, setTabIndex] = useState(0);
    const [charts, setCharts] = useState([]);
    const [dataContainerKeys, setDataContainerKeys] = useState([]);
    const [chartCount, setChartCount] = useState(1);
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };


    
    useEffect(() => {
        createAllCharts();
    }, []);


    const createAllCharts = () => {
        const dataContainerKeys = Object.keys(session.data);
        setDataContainerKeys(dataContainerKeys);
        const charts = dataContainerKeys.map((key, index) => ({ 
            id: index, 
            name: friendlyNameConverter(key),
            labels: session.data[key].map((i) => i.time),
            data: createData(key)
    }));
        // Sort by name
        charts.sort((a, b) => a.name.localeCompare(b.name));
        setCharts(charts);
    }



    const createData = (dataContainerKey) => {
        const series = [
            {
                type: 'line',
                name: dataContainerKey,
                fill: 'solid',
                data: session.data[dataContainerKey].map((i) => i.value)
            }
        ];
        return series;
    }

    const createChart = () => {
        setChartCount(chartCount + 1);
        setTabIndex(chartCount);
    }

    // eslint-disable-next-line no-unused-vars
    function ItemList({ count }) {
        const items = [];
        for (let i = 1; i <= count; i+=1) {
            items.push(<Tab key={i.toString()} label={i.toString()} />);
        }
        return {items};
    }
    
    const sensorTypes = Object.keys(sensorByTypes);
    // eslint-disable-next-line no-unused-vars
    const activeCharts = charts.filter((chart) => dataContainerKeys.includes(chart.name));
    return (    
        <Card>
            <Tabs value={tabIndex}
                onChange={handleTabChange} 
                variant="scrollable"
                scrollButtons="auto">
                {[...Array(chartCount)].map((_, index) => (
                    <Tab key={index} label={`Tab ${index}`}>
                        <SessionChartTab chart={charts[tabIndex]} charts={charts} sensorTypes={sensorTypes} events={events} tabindex={tabIndex} currentTime={currentTime} onChartClick={onChartClick} />
                    </Tab> 
                ))}
                <Tab label="+" onClick={createChart} />
            </Tabs>
            {charts.length === 0 && <Typography variant="h6" align="center">No charts to display</Typography>}
            {charts.length > 0 && <SessionChartTab chart={charts[tabIndex]} charts={charts} sensorTypes={sensorTypes} events={events} tabindex={tabIndex} currentTime={currentTime} onChartClick={onChartClick} />}
        </Card>

    );
}