import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@mui/material';

StandardChipDisplay.propTypes = {
    standards: PropTypes.object,
    onClick: PropTypes.func,
    color: PropTypes.string,
    onDelete: PropTypes.func
};

// const standardColorOptions = [
//     "#1f77b4",
//     "#ff7f0e",
//     "#2ca02c",
//     "#d62728",
//     "#9467bd",
//     "#8c564b",
//     "#e377c2",
//     "#7f7f7f",
//     "#bcbd22",
//     "#17becf",
//     "#393b79",
//     "#b2df8a",
//     "#fd8d3c",
//     "#7b3294",
//     "#f7b6d2",
//     "#4daf4a",
//     "#f0027f",
//     "#bf5b17",
//     "#a6cee3",
//     "#ffbb78",
//     "#2b83ba",
//     "#8a89a6",
//     "#e05c5d",
//     "#a1d76a",
//     "#de77ae"
// ];



function StandardChipDisplay(props) {
    return (
        <>
            {Object.values(props.standards).map(standard => (
                <Chip
                    key={standard.id}
                    label={standard.name}
                    onClick={() => props.onClick(standard)}
                    style={{ margin: '4px' }}
                    color={props.color}
                    onDelete={props.onDelete ? () => props.onDelete(standard) : null}
                />
            ))} 
            {(props.standards.length === 0) ? <br/> : null}
        </>
    );
}

export default StandardChipDisplay;