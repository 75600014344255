import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { ToastContainer} from 'react-toastify';
import { infoNotification } from '../../../services/notificationService';
import 'react-toastify/dist/ReactToastify.css';


import { requestPasswordReset } from '../../../services/authService';

// ----------------------------------------------------------------------

export default function RequestPasswordResetForm() {
  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState(false);

  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  const handleClick = () => {
    // Todo Check for Email Validation
    setAlreadySubmitted(true);
    requestPasswordReset(emailAddress).then(() => {
      infoNotification('Password reset request successful! We will redirect you here shortly!');
      setTimeout(() => navigate('/login', { replace: true }), 5000);
    });
  };


  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e) => setEmailAddress(e.target.value)} disabled={alreadySubmitted}/>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}/>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} disabled={alreadySubmitted}>
        Submit Request!
      </LoadingButton>
      <ToastContainer />
    </>
  );
}
