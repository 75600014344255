export const friendlyNameConverter = (sensorKey) => {
    const sensorSplit = sensorKey.split(':')
    if (sensorSplit.length !== 2) return sensorKey;
    const sensorType = sensorSplit[1].toLowerCase();
    const sensorName = sensorSplit[0] === "null" ? "" : sensorSplit[0];
    return `${sensorName} ${getSensorType(sensorType)}`;
}

export const getSensorType = (sensorType) => { 
    switch (sensorType.toLowerCase()) {
        case 'random':
            return `RNG`;
        case 'tvoc_data':
            return `VOC`;
        case 'co2_data':
            return `CO2`;
        case 'temperature_data':
            return `Temperature (C)`;
        case 'humidity_data':
            return `Humidity (%)`;
        case 'pressure_data':
            return `Pressure (Pa)`;
        case '0.3_per_100_cm^3_data':
            return `0.3um Particles`;
        case '0.5_per_100_cm^3_data':
            return `0.5um Particles`;
        case '1.0_per_100_cm^3_data':
            return `1um Particles`;
        case '2.5_per_100_cm^3_data':
            return `2.5um Particles`;
        case '5.0_per_100_cm^3_data':
            return `5um Particles`;
        case '10.0_per_100_cm^3_data':
            return `10um Particles`;
        case 'pm1.0_ultra_fine_particulates_data':
            return `PM1.0 Particulates`;
        case 'pm2.5_fine_particles_data':
            return `PM2.5 Particulates`;
        case 'pm10.0_data':
            return `PM10.0 Particulates`;
        default:
            return sensorType;
    }
}