import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
// @mui
import { Typography, Container, Divider } from '@mui/material';
import ReactMarkdown from 'react-markdown';
// ----------------------------------------------------------------------

export default function PrivacyPolicyPage() {

  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("assets/documents/PrivacyPolicy.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);



  return (
    <>
      <Helmet>
        <title> VPSI Privacy Policy </title>
      </Helmet>

      <Container>
      <Typography variant="h1" paragraph>
        Privacy Policy
      </Typography>
      <Divider sx={{ mb: 5 }} />
      <ReactMarkdown children={content} />
      </Container>
    </>
  );
}
