/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Card, CardContent } from '@mui/material';

SessionEventTimeLine.propTypes = {
    events: PropTypes.array,
    onEventClick: PropTypes.func,
    stepperActiveStep: PropTypes.number,
    setActiveStep: PropTypes.func
};

export default function SessionEventTimeLine({events, onEventClick, stepperActiveStep, setActiveStep}) {

    let steps;
    if (events === undefined) {
        steps = [];
    } else {
        steps = events;
    }

    if (steps.length === 0) {
        return (
            <></>
        )
    }

    return (
        <Card>
            <CardContent>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={stepperActiveStep}>
                        {steps.map((step, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            const handleClick = () => {
                                setActiveStep(index);
                                onEventClick(step);
                            };
                            return (
                                <Step key={index} {...stepProps} onClick={handleClick}>
                                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
            </CardContent>
        </Card>

    );
}