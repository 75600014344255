/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid, Button, Container, Stack, Typography, Card, CardContent, TextField, CardHeader, CardActions, ToggleButton } from '@mui/material';
// components
import { getSignedInUser, signOut } from '../services/authService';

import Iconify from '../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';

// mock
import POSTS from '../_mock/blog';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------


export default function AccountPage() {
  const navigate = useNavigate();

  const user = getSignedInUser();

  function onLogoutClick() {
    signOut();
    navigate('/login');
  }

  return (
    <>
      <Helmet>
        <title> VPSI | Account </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Account
          </Typography>
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} >
            <Card>
              <CardHeader title="Information" />
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <strong>Username:</strong> {user.username}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <strong>Email:</strong> {user.email}:
                </Typography>
                </CardContent>
            </Card>
          </Grid>
        <Grid item xs={12} sm={12} md={12} >
            <Card>
              <CardHeader title="Settings (Coming Soon)" />
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Manage your account settings and set email preferences.
                </Typography>
                <br />
                <Container direction="row" alignItems="right" justifyContent="space-between" mb={5}>
                  <Button variant="contained" color="primary">
                    Reset
                  </Button>
                  <Button variant="contained" color="primary">
                    Save
                  </Button> 
                </Container>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Card>
              <CardHeader title="Actions"/>
              <CardContent>
                <br /> 
                <Button variant="contained" color="primary" fullWidth onClick={() => onLogoutClick()}>
                  Logout
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
