import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { useNavigate } from 'react-router-dom';
// @mui
import { Avatar, Checkbox, IconButton, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
// components
import Iconify from '../../../components/iconify';

SessionTableBody.propTypes = {
    sessions: PropTypes.array,
    selected: PropTypes.array,
    handleClick: PropTypes.func,
    handleOpenMenu: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    emptyRows: PropTypes.number,
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    filterName: PropTypes.string,
};

function getDateString(dateAsString) {
    const date = new Date(dateAsString);
    const options = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    };
    const formattedDateTime = date.toLocaleString('en-US', options);
    return formattedDateTime;
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}



function SessionTableBody({ sessions, selected, handleClick, handleOpenMenu, page, rowsPerPage, emptyRows, order, orderBy, filterName }) {

    const filteredSessions = applySortFilter(sessions, getComparator(order, orderBy), filterName);

    const navigate = useNavigate();

    function onClicked(input, id) {
        const linkToNavigate = `/dashboard/session/${id}`;

        // move to the session page for the id
        navigate(linkToNavigate);
    }
    
    return (
        <TableBody>
            {filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, name, startTime, description } = row;
                const selectedSession = selected.indexOf(id) !== -1;
                return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedSession} aria-checked={selectedSession}>
                        <TableCell padding="checkbox">
                            <Checkbox checked={selectedSession} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                            <TableCell component="th" scope="row" padding="none" onClick={(event) => onClicked(event, id)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar alt={name} src={name} />
                                    <Typography variant="subtitle2" noWrap>
                                        {name}
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell align="left">{description}</TableCell>
                            <TableCell align="left">{getDateString(startTime)}</TableCell>
                            <TableCell align="right">
                                <IconButton onClick={handleOpenMenu}>
                                    <Iconify icon="bx:bxs-dots-horizontal-rounded" width={20} height={20} />
                                </IconButton>
                            </TableCell>

                    </TableRow> 
                );
            })}
            {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                </TableRow>
            )}
        </TableBody>
    );
}

export default SessionTableBody;