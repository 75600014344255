/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, Fab, Button} from '@mui/material';
// components
import { StandardSort, StandardsList, StandardCartWidget, StandardFilterSidebar, StandardCreationDialog } from '../sections/@dashboard/standards';
// mock
import {createRule, getAllRules} from '../services/ruleService';

// ----------------------------------------------------------------------

export default function StandardsPage() {
  const [openFilter, setOpenFilter] = useState(false);

  const [showCreateStandardDialog, setShowCreateStandardDialog] = useState(false);

  const [standards, setStandards] = useState([]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const loadPage = async () => {
    const allStandards = await getAllRules()
    setStandards(allStandards);
  }

  const addStandard = async (standard) => {
    const newStandard = await createRule(standard);
    setStandards([...standards, newStandard]);
  }


  const showCreationDialog = () => {
    setShowCreateStandardDialog(true);
  }

  const showEditDialog = (rule) => {
    console.log("Edit Rule: ", rule);
  }

  useEffect(() => {
    console.log('StandardsPage');
    loadPage();
  }, []);

  return (
    <>
      <Helmet>
        <title>VPSI | Rules</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
         Rules 
        </Typography>
        <Button variant="contained" onClick={showCreationDialog}>New</Button>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <StandardFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <StandardSort />
          </Stack>
        </Stack>

        <StandardsList standards={standards} onEdit={showEditDialog}/>
      </Container>
      <StandardCreationDialog open={showCreateStandardDialog} handleClose={() => setShowCreateStandardDialog(false)} 
        handleSave={addStandard}
        sensorTypes={['temperature', 'humidity', 'pressure']} 
      />
    </>
  );
}
