import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { ToastContainer} from 'react-toastify';
import { errorNotification, infoNotification } from '../../../services/notificationService';
import 'react-toastify/dist/ReactToastify.css';

import Iconify from '../../../components/iconify';

import { login } from '../../../services/authService';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();


  const [showPassword, setShowPassword] = useState(false);
  const [username, setUserName] = useState(false);
  const [password, setPassword] = useState(false);


  const handleClick = () => {
    logIn();
  };

  const logIn = () => {
      login(username, password).then(() => {
        infoNotification('Login successful');
        navigate('/dashboard/app', { replace: true });
      })
    .catch((error) => {
      errorNotification(`Login failed: ${error}`);
    });
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e) => setUserName(e.target.value)} />

        <TextField
          name="password"
          label="Password!"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel control={<Checkbox />} label="Remember me" />
        <Link variant="subtitle2" underline="hover" href="/requestPasswordReset">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
      <ToastContainer />
    </>
  );
}
