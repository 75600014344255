import Cookies from 'js-cookie';
import { axiosInstance } from './common'

export const getSignedInUser = () => JSON.parse(localStorage.getItem('user'));

export const login = (username, password) => axiosInstance.post(`/auth/signin`, { username, password }).then((r) => {
    localStorage.setItem('user', JSON.stringify(r.data))
    return r.data
});

export const createAccount = (username, email, password) => axiosInstance.post(`/auth/signup`, { username, password, email }).then((r) => {return r.data });

export const requestPasswordReset = (email) => axiosInstance.post(`/auth/requestPasswordReset`, { email }).then((r) => {return r.data });

export const passwordReset = (token, email, password) => axiosInstance.post(`/auth/resetPassword`, { token, email, password }).then((r) => { return r.data });


export const isSignedIn = () => Cookies.get("vpsi-jwt") !== undefined; 

export const signOut = () => axiosInstance.get(`/auth/signout`).then((r) => {
    Cookies.remove("vpsi-jwt");
    localStorage.removeItem('user');
    return r.data;
});
