/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Stack,
    Avatar,
    Button,
    Typography,
    TextField
} from '@mui/material';

SessionPageHeader.propTypes = {
    session: PropTypes.object,
    playing: PropTypes.bool,
    playClicked: PropTypes.func,
    pauseClicked: PropTypes.func,
    resetClicked: PropTypes.func,
    lagTime: PropTypes.number,
    lagTimeChanged: PropTypes.func,
};

function SessionPageHeader(props) {
    const startTime = new Date(props.session.startTime);
    const formattedStartTime = `${startTime.toLocaleDateString()} ${startTime.toLocaleTimeString()}`;
    const endTime = new Date(props.session.endTime);
    const formattedEndTime = `${endTime.toLocaleDateString()} ${endTime.toLocaleTimeString()}`;

    return (
        <Card >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 3 }}>
                <Typography variant="h3">
                    {props.session.name}
                </Typography>
                <Stack direction="column" spacing={1}>
                    <Typography variant="p" textAlign={'right'}>
                        Start Time: {formattedStartTime}
                    </Typography>
                    <Typography variant="p" textAlign={'right'}>
                        End Time: {formattedEndTime}
                    </Typography>
                </Stack>
                
                
            </Stack>
        </Card>
    );
}

export default SessionPageHeader;